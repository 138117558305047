<template>
  <div>
    <b-button variant="warning" size="sm" @click="clickModal" v-if="0"
      >Quick Reserve
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Quick Reserve"
      id="quick_reserve"
      ref="modal"
    >
      <b-overlay :show="loading">
        <b-row>
          <b-col md="12" class="my-1">
            <b-form-group
              label-for="supplier_name"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Supplier Name"
              class="required"
            >
              <SelectLoading
                v-if="option.supplier_name == 0"
                :size="0.7"
              ></SelectLoading>

              <sv-select
                v-model="supplier_name"
                :placeholder="$t('page.please_choose')"
                :options="option.supplier_name"
                :reduce="options => options.value"
                :clearable="false"
                :selectable="options => !options.disabled"
                disabled
                id="supplier_name"
                label="label"
              >
                <template slot="option" slot-scope="option" v-if="option.index">
                  <div class="d-center" :class="'level_' + option.index">
                    <span v-if="option.index == 2">--</span>
                    {{ option.label }}
                  </div>
                </template>
              </sv-select>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1" v-if="0">
            <b-form-group
              label-for="purchase_price"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Purchase Price"
              class="required"
            >
              <b-input-group>
                <template v-slot:append>
                  <b-input-group-text> € </b-input-group-text>
                </template>
                <b-form-input
                  v-model="purchase_price"
                  type="number"
                  min="0"
                  :placeholder="this.$t('page.please_choose_input')"
                  id="purchase_price"
                ></b-form-input
              ></b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1 mb-4">
            <b-form-group
              label-for="purchase_delivery_to"
              label-cols-sm="4"
              label-cols-lg="2"
              label-align-sm="right"
              label-size="sm"
              label="Delivery to"
            >
              <SelectLoading
                v-if="baseConfig.purchase_delivery_to == 0"
                :size="0.7"
              ></SelectLoading>
              <sv-select
                v-model="purchase_delivery_to"
                :placeholder="$t('page.please_choose')"
                :options="baseConfig.purchase_delivery_to"
                :reduce="options => options.value"
                :clearable="false"
                id="purchase_delivery_to"
                label="label"
              >
              </sv-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          size="sm"
          :disabled="disabled"
          variant="primary"
          @click="submit()"
        >
          Submit
        </b-button>
        <b-button size="sm" variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>
<script>
import SelectLoading from '@/components/Loading/Index';
import { mapState } from 'vuex';
// import SelectLoading from '@/components/Loading/Index';
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'QuickReserve',
  data: function() {
    return {
      showModal: false,
      loading: false,
      disabled: false,
      supplier_name: null,
      purchase_price: 0,
      purchase_delivery_to: null,
      option: {
        supplier_name: []
      }
    };
  },
  components: { SelectLoading },
  props: ['id', 'supplierName'],
  methods: {
    clickModal() {
      // console.log(this.option.supplier_name);
      if (this.option.supplier_name.length == 0) {
        apiBus.purchase
          .getSupplierList()
          .then(data => {
            let options = [];
            Object.keys(data.data.data.supplier_list).forEach(item => {
              options.push({
                label: item,
                value: item.replace(/ /g, '_'),
                index: 1,
                disabled: true
              });
              // console.log(item);
              data.data.data.supplier_list[item].forEach(x => {
                options.push({
                  label: x.label,
                  value: x.value,
                  index: 2
                });
              });
            });
            console.log(options);
            this.option.supplier_name =
              data.data.data.supplier_list['Other Supplier'];
            // this.option.supplier_name = data.data.data.supplier_list;
          })
          .catch(error => {
            console.log(error);
            this.$store.dispatch(PROMPT_STATUS, {
              dismissCountDown: 5, //初始提示作用于定时器
              alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
              alertText:
                error.data == undefined ? error.message : error.data.message // 提示内容
            });
          });
      }
      this.datapurchase_price = 0;
      this.purchase_delivery_to = null;
      this.showModal = true;
    },

    submit() {
      console.log(this.id);
      if (this.supplier_name == null || this.supplier_name == '') {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      // if (isNaN(Number(this.purchase_price)) || this.purchase_price <= 0) {
      //   this.$store.dispatch(PROMPT_STATUS, {
      //     dismissCountDown: 5, //初始提示作用于定时器
      //     alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
      //     alertText: '请正确填写purchase price' // 提示内容
      //   });
      //   return false;
      // }
      this.loading = true;
      this.disabled = true;
    },
    init() {}
  },
  computed: {
    ...mapState({
      baseConfig: state => state.baseconfig.baseconfig
    })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
