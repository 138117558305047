<template>
  <div class="loading">
    <div style="display: flex; align-items: flex-end">
      {{ $t('page.loading') }}
      <b-spinner
        small
        type="grow"
        :style="`width: ${size}rem; height: ${size}rem`"
      ></b-spinner>
      <b-spinner
        small
        type="grow"
        :style="`width: ${size}rem; height: ${size}rem`"
      ></b-spinner>
      <b-spinner
        small
        type="grow"
        :style="`width: ${size}rem; height: ${size}rem`"
      ></b-spinner>
      <b-spinner
        small
        type="grow"
        :style="`width: ${size}rem; height: ${size}rem`"
      ></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectLoading',
  data() {
    return {};
  },
  props: {
    size: Number
  },
  components: {},
  methods: {},

  computed: {},
  mounted() {}
};
</script>
<style scoped></style>
